import React from 'react'
import Goalone from '../assets/images/goal-1.jpg';
import Goaltwo from '../assets/images/goal-2.jpg';
import Goalthree from '../assets/images/goal-3.jpg';
import Goalfour from '../assets/images/goal-4.jpg';
import Goalfive from '../assets/images/goal-5.jpg';
import Goalsix from '../assets/images/goal-6.jpg';
import Goalseven from '../assets/images/goal-7.jpg';
import Goaleight from '../assets/images/goal-8.jpg';
import Goalnine from '../assets/images/goal-9.jpg';
import Goalten from '../assets/images/goal-10.jpg';
import Goaleleven from '../assets/images/goal-11.jpg';
import Goaltwele from '../assets/images/goal-12.jpg';
import Goalthirteen from '../assets/images/goal-13.jpg';
import Goalfourteen from '../assets/images/goal-14.jpg';
import Goalfifteen from '../assets/images/goal-15.jpg';
import Goalsixteen from '../assets/images/goal-16.jpg';
import Goalseventeen from '../assets/images/goal-17.jpg';
import Goalsdg from '../assets/images/sdg.png';

function Goals() {
  return (
    <div className='goals' id='goals'>
        <div className='containter-fluid'>
           <div className='row goal-row'>
              <h2 className='text-center mb-4 mt-4'>GOALS OF SDG</h2>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalone} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goaltwo} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalthree} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalfour} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalfive} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalsix} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalseven} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goaleight} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalnine} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalten} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goaleleven} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goaltwele} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalthirteen} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalfourteen} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalfifteen} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalsixteen} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalseventeen} alt=""/>
                </div> 
              </div>
              <div className='col-6 col-sm-6 col-md-2 col-lg-2 p-0'>
                <div className='goal-img'>
                  <img src={Goalsdg} alt=""/>
                </div> 
              </div> 
           </div>
        </div>
    </div>
  )
}

export default Goals