import React from 'react'
import Video from './Video';
import { FaCheck,FaTrophy } from 'react-icons/fa';
import Footer from './Footer';

const Rgf = () => {
  return (
    <>
      <Video/>
      <section id="rgf-content">
        <div className='container'>
          <div className='row'>
            <div className="indeanex">
              <div className="title text-center">
                <h2>RGF IDEANEX</h2>
              </div>
              <p>On March 30 and 31, 2023, Rathinam Technical Campus and the AIC RAISE will co-host the thrilling two-day Rathinam UN SDG Ideathon to promote innovation and advance sustainable development. Young innovators get the chance to create answers to the Sustainable Development Goals (SDGs) of the United Nations and present their concepts to a larger audience at the event.</p>
              <p>Students from various colleges, including engineering, commerce, and the social sciences, are welcome to participate in the Rathinam UN SDG Ideathon. Teams of participants will work together to generate concepts and create original solutions to tackle particular SDGs. Throughout the ideation and development process, the teams will have access to mentors and subject matter experts who will offer direction and assistance.</p>
              <p>The suggestions will be presented to a panel of judges at the conclusion of the competition, who will assess them according to their impact, viability, scalability, and sustainability. The winner team will receive a 1 lakh seed fund incentive to turn their idea into reality.</p>
              <p>The Rathinam UN SDG Ideathon is a great chance for young innovators to work together, gain knowledge, and change the world. The activity encourages innovation and advances sustainable development, making it a crucial undertaking in accomplishing the SDGs. The seed fund award of Rs. 1 lakh provides participants with an exhilarating incentive to create cutting-edge ideas that have a major positive social impact.</p>
            </div>
            <div className="col-md-8 col-lg-8 mt-4 mb-4 column-day">
              <div className="day-column">
                <h4>Day 1:</h4>
                <p><FaCheck /> Registration (9.00 am - 10.30 am)</p>
                <p><FaCheck /> Pitching desk (11.00 am - 1.00 pm)</p>
                <p><FaCheck /> Lunch (1.00 pm - 2.00 pm)</p>
                <p><FaCheck /> Business model (2.00 pm - 4.30 pm)</p>
                <p><FaCheck /> Announcement for the selected teams for final (4.30 pm - 5.00pm)</p>
                <h4>DAY 2:</h4>
                <p><FaCheck /> Final presentation (9.00 am -11.00 am)</p>
                <p><FaCheck /> Winner announcement (11.30 am - 12.00am)</p>
              </div>
            </div>
            <div className='Register-btn text-center'>
                <a href="https://pages.razorpay.com/rgf-ideanex" target="_blank" rel="noreferrer"><button>Register Here</button></a>
            </div>
          </div>
        </div>
      </section>
      <section id="trophy" className='mb-5 mt-4'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-5 col-lg-5 column-prize">
              <div className='prize-column'>
                <h2>Prize</h2>
                <FaTrophy/>
                <p>1 Lakh seed fund from Atal Incubation Centre RAISE, Supported by AIM, NITI Aayog, Govt of India.</p>
              </div>
            </div>
            <div className="col-md-7 col-lg-7">
              <div className='rules'>
                <h2>Rules</h2>
                <ul>
                    <li><FaCheck /> <p>Idea should be only related to SDG goals</p></li>
                    <li><FaCheck /> <p>A team should consist maximum 3 members</p></li>
                    <li><FaCheck /> <p>If prototype is available, all necessary item should be prepare by yourself</p></li>
                    <li><FaCheck /> <p>It must clearly state name of the team and all team members and must be duly signed by college principle/dean/institute director</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div> 
      </section>
      <section id="form-sec" className='mb-5 mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-lg-8' style={{margin: "auto"}}>
               <div className='text-center'>
               <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc4i8vvub7uLpPI3ua56H1aYuI65aaKlVw3umaspJduo6j2oA/viewform?embedded=true" title="RGF" width="640" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
               </div>
            </div>  
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default Rgf
