import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';

function Activity() {
  return (
    <div className='activity' id='activity'>
        <div className='container'>
            
                <div className='activity-section'>
                <div className='row'>
                    <h2 class="text-center mb-4 mt-4">ACTIVITIES PLANNED</h2>
                    <div className='col-md-8 col-lg-8'>
                        <div className='activity-list'>
                            <ul>
                                
                                <li><FaCheck /> <p>Host internal events and activities focused on regionally important SDGs.</p></li>
                                <li><FaCheck /> <p>Work together with other local or socialist groups, clubs, or initiatives.</p></li>
                                <li><FaCheck /> <p>Create frequent activities and seminars to include and inform the student body on the SDGs.</p></li>
                                <li> <FaCheck /><p>Create chances for the hub community to develop and put into action solutions that speed up regional and governmental progress on the SDGs.</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4'>
                        <div className='contact'>
                            <h3>CONTACT</h3>
                            <div className='nameList'>
                                <h4>Mrs. Ruth Keziah.M</h4>
                                <p><FaPhoneAlt/>+91 96009 17512</p>
                            </div>
                            <div className='nameList'>
                                <h4>Ms. Edith Evangeline</h4>
                                <p><FaPhoneAlt/>+91 70949 90537</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Activity