import React from 'react'
import PRESIDENT from '../assets/images/students/kannan.png';
import ARIVANANDHAN from '../assets/images/students/ARIVANANDHAN_CHITHESHWARAN.png';
import RASINA from '../assets/images/students/RASINA_PARVEEN.png';
import NANDU from '../assets/images/students/NANDU.png'

function Ourteam() {
  return (
    <div className='team' id='team'>
        <div className='container'>
            <div className='row'>
                 <div className='team-head'>
                   <h2 className='text-center mb-4 mt-4'>OUR TEAM</h2>
                 </div> 
                 <div className='col-md-3 col-lg-3'>
                    <div className='team-section'>
                        <div className='team-img text-center'>
                            <img src={PRESIDENT} alt=""/>
                        </div>
                        <div className='team-details text-center'>
                            <h4 className="mb-1 mt-1">PRESIDENT</h4>
                            <p className="mb-0 mt-1">Kannan</p>
                             
                        </div>
                    </div>
                 </div>
                 <div className='col-md-3 col-lg-3'>
                    <div className='team-section'>
                        <div className='team-img text-center'>
                            <img src={ARIVANANDHAN} alt=""/>
                        </div>
                        <div className='team-details text-center'>
                            <h4 className="mb-1 mt-1">VICE PRESIDENT</h4>
                            <p className="mb-1 mt-1">Arivanandhan Chitheshwaran</p>
                             
                        </div>
                    </div>
                 </div>
                 <div className='col-md-3 col-lg-3'>
                    <div className='team-section'>
                        <div className='team-img text-center'>
                            <img src={RASINA} alt=""/>
                        </div>
                        <div className='team-details text-center'>
                            <h4 className="mb-1 mt-1">SECRETARY</h4>
                            <p className="mb-1 mt-1">Rasina Parveen</p>
                             
                        </div>
                    </div>
                 </div>
                 <div className='col-md-3 col-lg-3'>
                    <div className='team-section'>
                        <div className='team-img text-center'>
                            <img src={NANDU} alt=""/>
                        </div>
                        <div className='team-details text-center'>
                            <h4 className="mb-1 mt-1">TREASURER</h4>
                            <p className="mb-1 mt-1">Nandhakumar</p>
                             
                        </div>
                    </div>
                 </div>
            </div>
        </div>
    </div>
  )
}

export default Ourteam