import React from 'react'

function StudentList() {
    // const images = [
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-1.jpg",
    //         "text": "To end poverty in all its forms.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-1.jpg",
    //         "name": "RAJAJI",
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-2.jpg",
    //         "text": "To end hunger, achieve food security and improved nutrition .and promote sustainable agriculture.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-2.jpg",
    //         "name": "HARSHAVARTHAN",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-3.jpg",
    //         "text": "To ensure healthy lives and promote well-being for all at all ages.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-3.jpg",
    //         "name": "VELMURUGAN",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-4.jpg",
    //         "text": "Ensure inclusive and quality education for all and promote lifelong learning.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-4.jpg",
    //         "name": "THIVAKARAN",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-5.jpg",
    //         "text": "To achieve gender equality and empower all women and girls.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-5.png",
    //         "name": "GLADWIN",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-6.jpg",
    //         "text": "To ensure access to safe water sources and sanitation for all.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-6.jpg",
    //         "name": "HARISH KUMAR",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-7.jpg",
    //         "text": "To ensure access to affordable, reliable, sustainable and modern energy for all.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-7.jpg",
    //         "name": "MANIKKAVASAKI",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-8.jpg",
    //         "text": "To promote inclusive and sustainable economic growth, employment and decent work for all.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-8.png",
    //         "name": "RUTH PRINCY",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-9.jpg",
    //         "text": "To build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-9.jpg",
    //         "name": "JOTHISWARAN",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-10.jpg",
    //         "text": "To reduce inequalities within and among countries.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-10.png",
    //         "name": "DEVAKAVIS",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-11.jpg",
    //         "text": "To make cities inclusive, safe, resilient and sustainable.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-11.jpg",
    //         "name": "AKASH",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-12.jpg",
    //         "text": "To ensure sustainable consumption and production patterns.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-12.png",
    //         "name": "KRISHNAPRASANTH",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-13.jpg",
    //         "text": "Taking urgent action to tackle climate change and its impacts.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-13.png",
    //         "name": "SIVAPRAHUL",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-14.jpg",
    //         "text": "To conserve and sustainably use the world’s ocean, seas and marine resources.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-14.png",
    //         "name": "DIBAKARAN",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-15.jpg",
    //         "text": "To sustainably manage forests, combat desertification, halt and reverse land degradation, and halt biodiversity loss.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-15.jpg",
    //         "name": "VAISHALI MM",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-16.jpg",
    //         "text": "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-16.png",
    //         "name": "SATHEESH",
             
    //     },
    //     {
    //         "sdg": "http://rathinamtechnicalcampus.com/stu-img/goal-17.jpg",
    //         "text": "To revitalize the global partnership for sustainable development.",
    //         "student": "http://rathinamtechnicalcampus.com/stu-img/student-17.png",
    //         "name": "JEYARAJ",
            
    //     },
    // ]
  return (
    <div className='studentList mt-4' id='studentList'>
        <div className='container-fluid'>
            <div className='row'>
                
                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-1-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-1.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To end poverty in all its forms.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-1.jpg" alt=""/>
                            <p className='mb-0'>RAJAJI</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-2-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-2.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To end hunger, achieve food security and improved nutrition .and promote sustainable agriculture.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-2.jpg" alt=""/>
                            <p className='mb-0'>HARSHAVARTHAN</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-3-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-3.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To ensure healthy lives and promote well-being for all at all ages.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-3.jpg" alt=""/>
                            <p className='mb-0'>VELMURUGAN</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-4-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-4.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>Ensure inclusive and quality education for all and promote lifelong learning.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-4.jpg" alt=""/>
                            <p className='mb-0'>THIVAKARAN</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-5-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-5.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To achieve gender equality and empower all women and girls.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-5.png" alt=""/>
                            <p className='mb-0'>GLAWIN</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-6-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-6.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To ensure access to safe water sources and sanitation for all.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-6.jpg" alt=""/>
                            <p className='mb-0'>HARISH KUMAR</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-7-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-7.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To ensure access to affordable, reliable, sustainable and modern energy for all.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-7.jpg" alt=""/>
                            <p className='mb-0'>MANIKKAVASAKI</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-8-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-8.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To promote inclusive and sustainable economic growth, employment and decent work for all.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-8.png" alt=""/>
                            <p className='mb-0'>RUTH PRINCY</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-9-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-9.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-9.jpg" alt=""/>
                            <p className='mb-0'>JOTHISWARAN</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-10-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-10.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To reduce inequalities within and among countries.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-10.png" alt=""/>
                            <p className='mb-0'>DEVAKAVIS</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-11-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-11.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To make cities inclusive, safe, resilient and sustainable.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-11.jpg" alt=""/>
                            <p className='mb-0'>AKASH</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-12-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-12.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To ensure sustainable consumption and production patterns.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-12.png" alt=""/>
                            <p className='mb-0'>KRISHNAPRASANTH</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-13-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-13.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>Taking urgent action to tackle climate change and its impacts.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-13.png" alt=""/>
                            <p className='mb-0'>SIVAPRAHUL</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-14.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-14.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To conserve and sustainably use the world’s ocean, seas and marine resources.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-14.png" alt=""/>
                            <p className='mb-0'>DIBAKARAN</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-15-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-15.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To sustainably manage forests, combat desertification, halt and reverse land degradation, and halt biodiversity loss.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-15.jpg" alt=""/>
                            <p className='mb-0'>VAISHALI MM</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-16-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-16.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-16.png" alt=""/>
                            <p className='mb-0'>SATHEESH</p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 col-lg-6 p-0 col-bgimg' style={{backgroundImage: "linear-gradient(hsl(0deg 0% 17% / 55%), hsl(0deg 0% 17% / 58%)),url(../images/img-goal-17-min.png)"}}>
                    <div className='rotate-circle'>
                        <div className='rotate-section'>
                            <img className="rotate" src="../images/sdg-wheel-logo-min.png" alt=""/>
                            <div className='sdg-img-inner'>
                            <img src="../images/goal-17.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="sdg-content">
                        <div className="sdg-main-content">
                        <h3>To revitalize the global partnership for sustainable development.</h3>
                        </div>
                        <div className='sdg-student-img'>
                            <h6>student ambassador</h6> 
                            <img src="../images/student-17.png" alt=""/>
                            <p className='mb-0'>JEYARAJ</p>
                        </div>
                    </div>
                </div>

                 
            </div>
        </div>
    </div>
  )
}

export default StudentList