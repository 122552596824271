import React from 'react'
import Mission from '../assets/images/mission.png'
import Vision from '../assets/images/vision.png'

function About() {
  return (
    <div className='about' id='about'>
        <div className='container'>
            <div className='row about-row'>
                <div className='col-md-4 col-lg-4 about-head'>
                    <div className='about-title'>
                        <h2>About <br/>Rathinam <br/>SDG Hub</h2>
                    </div>
                </div>
                <div className='col-md-8 col-lg-8'>
                    <div className='about-content'>
                        <p>An initiative of our youth, the “Rathinam SDG Hub” seeks to include students in higher education in the global drive to realise the 2030 Agenda to equip them with the information, skills, and paths to action to be powerful agents to make our world a better place and more sustainable future for all.</p>
                        <p>The Rathinam SDG Hub Program manages a network of student volunteers to cultivate effective, lifelong champions for sustainable development in their local communities.</p>
                        <p>We run these hubs by recruiting SDG ambassadors and hub members for each goals headed by SDG student leaders to include students in the SDGs on their campuses. This entails creating training materials, supervising follow-up and reporting activities, and thinking of fresh engagement strategies. Our goal is  to serve as centres for SDG inculcation and involvement, and for students to be innovators of fresh approaches to local issues.</p>
                    </div>
                </div>
            </div>
            <div className='row mission-vison'>
                <div className='col-md-6 col-lg-6'>
                    <div className='goal-block'>
                        <div className='goal mission'>
                            <div className='img-block'>
                                <img src={Mission} alt="Mission"/>
                                <h2>Mission</h2>
                            </div>
                            <p>Our vision is that students engages with social and environmental challenges during their time at college, empowering them to become active citizens: we do this by mainstreaming student social action, providing practical opportunities alongside training and skill development. We also make sure that the nature is taken into consideration in SDG implementation in a way that benefits local communities and the environment.</p>
                        </div> 
                    </div>
                </div>
                <div className='col-md-6 col-lg-6   '>
                    <div className='goal-block'>
                        <div className='goal vision'>
                            <div className='img-block'>
                                <img src={Vision} alt="Vision"/>
                                <h2>Vision</h2>
                            </div>
                            <p>A dynamic network of Rathanamites, where students may join together to learn about, discuss, and implement the SDGs.</p>
                            <p>Our Hub intends to promote positive habits and create a space where young people’s ideas may be debated in order to help them adopt new behaviors that support the SDGs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About