import React from 'react'

function Footer() {
  return (
    <div className="footer">
        <div className='copyRight'>
            <p className='m-0'>&copy; 2022. All Rights Reserved</p>
        </div>
    </div>
  )
}

export default Footer