import './App.css';
import { Routes, Route } from 'react-router-dom';
import RGF from './components/Rgf';
import Home from './components/Home';

function App() {
  return (
     <>
       <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/rgf" element={<RGF/>}/>
       </Routes>
     </>
  );
}

export default App;
