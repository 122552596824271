import React from 'react';
import Video from './Video';
import About from './About';
import Goals from './Goals';
import Team from './Ourteam';
import StudentList from './StudentList';
import Footer from './Footer';
import Activity from './Activity';

const Home = () => {
  return (
    <>
      <Video/>
      <About/>
      <Goals/>
      <Activity/>
      <Team/>
      <StudentList/>
      <Footer/>
    </>
  )
}

export default Home
