import React from 'react'
// import VideoBg from '../assets/video/The-SDG-Academy-Trailer.mp4';
import Bgbanner from '../assets/images/bg-banner-min.jpg'
import Logo from '../assets/images/SDG-Logo.png';

function Video() {
  return (
    <div className='main'>
        <div className='overlay'></div>
        {/* <video src={VideoBg} autoPlay loop muted/> */}
        <img src={Bgbanner} alt="" className='background-banner'/> 
        <div className='content'>
            <img src={Logo} alt="SDG Logo"/>
            <h1>RISE TO CHANGE</h1>
        </div>
    </div>
  )
}

export default Video